
























































































































































































































































































































































































































































































































































































































































































































































































































































































.card-box {
  margin-bottom: 10px;
  height: 80px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 16%;
    // min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      // line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../../assets/six.png);
    background-size: 100% 100%;
  }
  & > :nth-child(2) {
    background-image: url(../../../assets/first.png);
    background-size: 100% 100%;
  }
  & > :nth-child(3) {
    background-image: url(../../../assets/second.png);
    background-size: 100% 100%;
  }
  & > :nth-child(4) {
    background-image: url(../../../assets/third.png);
    background-size: 100% 100%;
  }
  & > :nth-child(5) {
    background-image: url(../../../assets/fourth.png);
    background-size: 100% 100%;
  }
  & > :nth-child(6) {
    background-image: url(../../../assets/five.png);
    background-size: 100% 100%;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
/deep/.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
